import { memo, FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation as t } from '@utils/hooks';
import SectionWrapper from '@components/SectionWrapper';
import SwimLaneWrapper from '@components/SwimLaneWrapper';
import type { DatoProduct, DatoProductImage } from '@framework/api/types';
import 'keen-slider/keen-slider.min.css';
import SectionHeading from '@components/SectionHeading';
import * as styles from './styles';

export type Props = {
  eyebrowLogo?: DatoProductImage;
  heading?: string;
  subheading?: string;
  textPosition?: 'left' | 'center' | string;
  products?: DatoProduct[];
  currencyCode: string;
  isextendedcard?: boolean;
};

const RelatedItems: FC<Props> = ({
  products,
  eyebrowLogo,
  heading,
  subheading,
  textPosition,
  currencyCode,
  isextendedcard,
}) => {
  const { locale } = useRouter();

  const [relatedItemsKey, setrelatedItemsKey] = useState(0);
  useEffect(() => {
    setrelatedItemsKey(relatedItemsKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const renderedHeading = heading || t('title_related_items');

  return Array.isArray(products) && products?.length > 0 ? (
    <SectionWrapper
      id={`related_anchor_${heading}`}
      data-test-id="related-product-block"
    >
      {textPosition === 'center' ? (
        <SectionHeading
          heading={heading}
          eyebrowLogo={eyebrowLogo}
          subheading={subheading}
          lang={locale}
        />
      ) : (
        <div className={styles.headingWrapper}>
          {products?.length > 0 && (
            <h3
              className={styles.title}
              data-test-id="related-product-left-heading"
            >
              {renderedHeading}
            </h3>
          )}
        </div>
      )}
      <SwimLaneWrapper
        key={relatedItemsKey}
        products={products}
        heading={t('title_related_items')}
        currencyCode={currencyCode}
        isExtendedCard={isextendedcard}
        eventType="productRecommend"
        asRelatedItems
      />
    </SectionWrapper>
  ) : null;
};

export default memo(RelatedItems);
